import { ColorBlack, ColorWhite } from "./Colors"

// If right color in right place = black
// if right color in wrong place = white

const ColorCheck = (colorsSolution, colorsInput) => {
  const colors = [...colorsInput]
  const colorsFeedback = []
  
  const checkPosition = i => colorsSolution[i] === colors[i]
  const checkInclude = i => colors.includes(colorsSolution[i])
  
  const checkColor = () => {
    for (let i = 0; i < colorsSolution.length; i++) {
      if (checkInclude(i)) {
        checkPosition(i)
          ? colorsFeedback.push(ColorBlack)
          : colorsFeedback.push(ColorWhite) 
      } 
    }
  }

  checkColor()

  // First all blacks and after all whites
  // reverse = before was white before black
  const feedback = colorsFeedback.sort().reverse().join('')
  return feedback
}

export default ColorCheck