import React from 'react'
import './App.css'
import GameButton from './GameButton'

const HeaderInfo = ({ gameStatus, solution, onClick }) => {
  const gameHeader = gameStatus => {
    switch(gameStatus) {
      case 'win':
        return (
          <>
            <p className="gameTitle">YOU WON <span role="img" aria-label="partyface">🥳</span></p>
            <div className="solutionAndButton">
              <p className="solution"><span role="img" aria-label="colors">{solution}</span></p>
              <GameButton value="NEW GAME" onClick={onClick}/>
            </div>
          </>
        )
        case 'lost':
          return (
          <>
            <p className="gameTitle">YOU LOST <span role="img" aria-label="faceupsidedown">🙃</span></p>
            <div className="solutionAndButton">
              <p className="solution"><span role="img" aria-label="colors">{solution}</span></p>
              <GameButton value="NEW GAME" onClick={onClick}/>
            </div>
          </>
        )
      default:
        return <p className="gameTitle">Let's solve it</p>
    }
  }

  return (
    <div className="headercontainer">
      {gameHeader(gameStatus)}
    </div>
  )
}

export default HeaderInfo