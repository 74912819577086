/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useCallback, useEffect, useState } from 'react'
import './App.css'

import ColorCheck from './ColorCheck'
import GameButton from './GameButton'
import Get4RandomColors from './Get4RandomColors'
import HeaderInfo from './HeaderInfo'
import LinePerRound from './LinePerRound'
import { ColorsGame, NumberOfColors } from './Colors'

function Game() {
  const [solutionColors, setSolutionColors] = useState([])
  const [inputColors, setInputColors] = useState('')
  const [feedbackColors, setFeedbackColors] = useState('')
  const [gameStatus, setGameStatus] = useState('game')
  const [roundNo, setRoundNo] = useState(1)
  const [previousRounds, setPreviousRounds] = useState([])

  const handleButtonNew = useCallback(() => {
    setInputColors('')
    setFeedbackColors('')
    setGameStatus('game')
    setRoundNo(1)
    setPreviousRounds([])
    const colorsOfTheGame = Get4RandomColors(ColorsGame, NumberOfColors)
    setSolutionColors(colorsOfTheGame)
  }, [])

  // Start the game
  useEffect(() => {
    const colorsOfTheGame = Get4RandomColors(ColorsGame, NumberOfColors)
    setSolutionColors(colorsOfTheGame)
  }, [])

  const handleButtonClick = buttonValue => {
    if (buttonValue === '✅') {
      if (inputColors.length !== 8) {
        return
      }

      const checkRoundNo = round => {
        if (round + 1 === 10) {
          return 'last'
        }
        return round + 1
      }
      
      const feedback = ColorCheck(solutionColors, inputColors)
      setFeedbackColors(feedback)

      if (feedback.includes('⚫️⚫️⚫️⚫️') && previousRounds.length <= 9) {
        setGameStatus('win')
        return
      }

      if (!feedback.includes('⚫️⚫️⚫️⚫️') && previousRounds.length === 9) {
        setGameStatus('lost')
        return
      }

      setPreviousRounds([...previousRounds, {roundNo, inputColors , feedback, solutionColors}])
      setRoundNo(checkRoundNo(roundNo))
      setInputColors('')
      setFeedbackColors('')
    }

    else if (buttonValue === '⏪') {
      // why 'slice -2': Icon + Color = 2
      const deleteInput = inputColors.slice(0, -2) 
      return setInputColors(deleteInput)
    }

    else {
      // why '8': (Icon + Color) * 4Colors = 8
      if (inputColors.length >= 8) {  
        return
      }
      const addInput = inputColors + buttonValue
      return setInputColors(addInput)
    }
  }

  return (
    <div className="mastermind">
      <HeaderInfo 
        gameStatus={gameStatus}
        solution={solutionColors}
        onClick={() => handleButtonNew()}
      />
      {previousRounds.map((i, idx) => (
        <LinePerRound
          key={idx}
          roundNo={i.roundNo}
          valueInput={i.inputColors}
          valueOutput={i.feedback}
        />
      ))}
      <LinePerRound
        roundNo={roundNo}
        valueInput={inputColors}
        valueOutput={feedbackColors}
      />
      <div className="buttons">
        <GameButton value="🔴" onClick={() => handleButtonClick('🔴')}/>
        <GameButton value="🟠" onClick={() => handleButtonClick('🟠')}/>
        <GameButton value="🟡" onClick={() => handleButtonClick('🟡')}/>
        <GameButton value="🟢" onClick={() => handleButtonClick('🟢')}/>
        <GameButton value="🔵" onClick={() => handleButtonClick('🔵')}/>
        <GameButton value="🟣" onClick={() => handleButtonClick('🟣')}/>
        <GameButton value="⏪" onClick={() => handleButtonClick('⏪')}/>
        <GameButton value="✅" onClick={() => handleButtonClick('✅')}/>
      </div>
    </div>
  )
}

export default Game