/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import './App.css';
import Game from './Game';
import Intro from './Intro'

const App = () => {
  return (
    <>
      {/* <Intro /> */}
      <Game />
    </>
  )
}

export default App