import React from 'react'

const GameButton = props => {
  return (
    <button onClick={props.onClick}>
      {props.value}
    </button>
  )
}

export default GameButton