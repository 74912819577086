import React from 'react'
import './App.css'

const InputField = props => {
  return (
    <input
      type="text"
      id="displayInput"
      disabled
      value={props.value}
    />
  )
}

export default InputField