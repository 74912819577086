import React from 'react'
import './App.css'
import RoundNo from './RoundNo.js'
import InputField from './InputField'

const LinePerRound = props => {
  return (
    <div className="gamecontainer">
      <RoundNo value={props.roundNo}/>
      <InputField value={props.valueInput}/>
      <InputField value={props.valueOutput}/>
    </div>
  )
}

export default LinePerRound