import React from 'react'
import './App.css'

const RoundNo = ({ value }) => {
  return (
    <div className="round">
      <p>{value}</p>
    </div>
  )
}

export default RoundNo